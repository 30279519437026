// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'
import Typed from 'typed.js';

const scrollSpy = new bootstrap.ScrollSpy(document.body, {
    target: '#navbarNavDropdown'
  })


/**
   * Easy selector helper function
   */
const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}

/**
   * Hero type effect
   */
const typed = select('.typed')
if (typed) {
  let typed_strings = typed.getAttribute('data-typed-items')
  typed_strings = JSON.parse(typed_strings)
  new Typed('.typed', {
    strings: typed_strings,
    loop: true,
    typeSpeed: 100,
    backSpeed: 50,
    backDelay: 2000
  });
}